import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import rizoom from '../assets/images/rizoom.svg';
import TransformNav from '../components/TransformNav';
import PageTransition from '../components/PageTransition';
import SEO from '../components/Seo';

const ArchiefStyles = styled.div`
  display: block;
  .logo {
    grid-column: 1 / span 8;
    padding-bottom: 30px;
    @media screen and (min-width: 64em) {
      grid-column: 1 / span 6;
      padding-bottom: 120px;
    }
    img {
      width: 100%;
    }
  }
  .filters {
    grid-column: span 12;
    @media screen and (min-width: 64em) {
      grid-column: span 6;
      padding-bottom: 120px;
    }
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;
const ArtistCard = styled.div`
  position: relative;
  cursor: pointer;
  &:before {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    z-index: 100;
    transition: 0.2s all ease-in-out;
    background: var(--lightbeige);
    opacity: 0;
  }
  &:hover {
    &:before {
      opacity: 0.2;
    }
  }
  .projectImage {
    height: 206px;
    margin-bottom: 5px;
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      height: 236px;
      margin-bottom: 25px;
    }
  }
  img,
  picture source,
  picture,
  picture {
    height: 163px;
    width: 163px;
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      height: 236px;
      width: 236px;
    }
    @media screen and (min-width: 64em) {
      height: 206px;
      width: 206px;
    }
  }
  picture img {
    height: inherit !important;
  }

  a {
    text-decoration: none;
    margin-top: 5px 0 15px;
    display: block;
  }
  .name {
    font-size: 17px;
    color: black;
    font-family: 'regular';
    @media screen and (max-width: 39.9375em) {
      transform: translateY(-30px);
    }
  }

  grid-column: span 6;
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    grid-column: span 4;
  }
  @media screen and (min-width: 64em) {
    grid-column: span 3;
  }
`;

export default function archief({ data }) {
  const artists = data.artists.nodes;
  const [activeMakers, setActiveMakers] = useState(false);
  const result = artists.filter((artist) => artist.actueel === false);
  return (
    <>
      <PageTransition>
        <SEO title={data.page.seo_title} desc={data.page.seo_description} />
        <TransformNav />
        <div className="wrapper background">
          <ArchiefStyles>
            <div className="block">
              <div className="logo">
                <img src={rizoom} width="296" alt="Rizoom" />
              </div>
              <div className="filters" />

              {!result.length ? (
                <ArtistCard style={{ gridColumn: 'span 12' }}>
                  <p>Geen makers in het archief</p>
                </ArtistCard>
              ) : null}

              {artists?.map((artist, i) => (
                <>
                  {artist?.slug?.current !== undefined ? (
                    <React.Fragment key={i}>
                      {!artist.actueel ? (
                        <ArtistCard key={i}>
                          <Link key={i} to={`/artist/${artist?.slug?.current}`}>
                            {artist?.image?.asset ? (
                              <Img
                                fluid={artist?.image?.asset.fluid}
                                className="projectImage"
                              />
                            ) : null}
                            <div className="name">{artist.name}</div>
                          </Link>
                        </ArtistCard>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </>
              ))}
            </div>
          </ArchiefStyles>
        </div>
      </PageTransition>
    </>
  );
}

export const query = graphql`
  query Archief {
    artists: allSanityArtist {
      nodes {
        _id
        name
        _rawRichText
        discipline {
          name
        }
        actueel
        slug {
          current
        }
        image {
          asset {
            fluid(maxWidth: 2100) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    page: sanityPage(slug: { current: { eq: "archief" } }) {
      name
      seo_title
      seo_description
    }
    footer: allSanitySiteSettings {
      edges {
        node {
          address_1
          address_2
          address_3
          facebook_url
          email
          instagram_url
          phone
          studio_title
          title
          title_1
          title_2
          vimeo_url
        }
      }
    }
  }
`;
